import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
//import DocLayout from "./layouts/Doc";

// Guards
//import AuthGuard from "./components/guards/AuthGuard";



// Protected routes
//import Main from "./pages/dashboard/Main";

const Main = async(() => import("./pages/dashboard/Main"));
const Pole = async(() => import("./pages/pole/Pole"));
const Page404 = async(() => import("./pages/auth/Page404"));


const routes = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "/",
        element: <Main />,
      },
      {
        path: "/pole/:id",
        element: <Pole />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
